<template>
  <div class="main-container">
    <div>
      <h5 class="pageTitle">세션 타임아웃 설정</h5>
    </div>
    <mdb-container class="form-container">
      <p>현재 세션 타임아웃:</p>
      <mdb-input 
        type="text" 
        label="" 
        outline 
        class="m-1" 
        v-model="currentTimeoutDisplay" 
        disabled
      />
      <br/>
      <p>신규 세션 타임아웃 설정:</p>
      <div class="time-settings">
        <label for="hours">시간:</label>
        <select v-model="newHours" id="hours">
          <option v-for="hour in hoursOptions" :key="hour" :value="hour">{{ hour }}</option>
        </select>
        <label for="minutes">분:</label>
        <select v-model="newMinutes" id="minutes">
          <option v-for="minute in minutesOptions" :key="minute" :value="minute">{{ minute }}</option>
        </select>
      </div>
    </mdb-container>
    <mdb-container class="text-right">
      <mdb-btn outline="primary" @click="applySettings">적용</mdb-btn>
    </mdb-container>
  </div>
</template>

<script>
import { mdbContainer, mdbInput, mdbBtn } from 'mdbvue';
import { mapState, mapActions } from 'vuex';
import Swal from 'sweetalert2';

export default {
  name: 'Session',
  components: {
    mdbContainer,
    mdbInput,
    mdbBtn
  },
  computed: {
    ...mapState(['sessionTimeout']),
    currentTimeoutDisplay() {
      // 현재 세션 타임아웃을 시간과 분으로 변환하여 표시
      const seconds = parseInt(this.sessionTimeout.replace('s', ''), 10);
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      return `${hours}시간 ${minutes}분`;
    },
    totalTimeoutSeconds() {
      // 시간과 분을 초로 변환
      const hoursInSeconds = parseInt(this.newHours, 10) * 3600;
      const minutesInSeconds = parseInt(this.newMinutes, 10) * 60; // 분을 초로 변환
      return hoursInSeconds + minutesInSeconds;
    }
  },
  data() {
    return {
      newHours: 24, // 기본값을 24시간으로 설정
      newMinutes: 0, // 기본값을 0분으로 설정
      hoursOptions: Array.from({ length: 25 }, (_, i) => i), // 0부터 24까지
      minutesOptions: Array.from({ length: 60 }, (_, i) => i) // 0부터 59까지
    };
  },
  methods: {
    ...mapActions(['updateSessionTimeout']),
    async applySettings() {
      if (this.newHours === 0 && this.newMinutes === 0) {
        await Swal.fire({
          title: '유효하지 않은 입력',
          text: '입력값이 0보다 커야 합니다.',
          icon: 'error'
        });
        return;
      }

      const timeout = `${this.totalTimeoutSeconds}s`;

      if (isNaN(this.totalTimeoutSeconds) || this.totalTimeoutSeconds <= 0) {
        await Swal.fire({
          title: '유효하지 않은 입력',
          text: '유효한 숫자를 입력해 주세요.',
          icon: 'error'
        });
        return;
      }

      // 로컬 스토리지에 저장
      sessionStorage.setItem('sessionTimeout', timeout);
      await this.updateSessionTimeout(timeout);
      const setuptime =this.currentTimeoutDisplay;

      await Swal.fire({
        title: '설정 완료',
        text: `세션 타임아웃이 ${setuptime}으로 적용되었습니다.`,
        icon: 'success'
      });

      await Swal.fire('로그아웃!', '설정의 적용을 위해 로그아웃 되었습니다.', 'success')
        .then(() => {
          sessionStorage.removeItem('token');        
          this.$router.push({ path: '/' }).catch(() => true);
        });
    }
  }
};
</script>

<style scoped>
.main-container {
  padding: 15px;
}

.pageTitle {
  font-weight: bold;
}

.form-container {
  margin-top: 30px;
}

.text-right {
  text-align: right;
}

.time-settings {
  display: flex;
  align-items: center;
  gap: 10px;
}

#hours,
#minutes {
  margin-right: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

label {
  font-weight: bold;
}
</style>
